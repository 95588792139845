import React, { ReactElement } from 'react'

function Page404({}: any): ReactElement {
    return (
        <div>
            Not Found
        </div>
    )
}

export default Page404
